.blogpost-root {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.blogpost-headline {
    width: 54rem;
    text-align: center;
    font-size: 2.7em;
    font-weight: 800;
    color: #f58220;
}

.blogpost-imgs {
    width: 50rem;
}

.blogpost-text {
    width: 50rem;
    text-align: left;
    font-size: 1.2em;
    color: white;
}

.blogpost-text > a {
    color: white;
}

@media screen and (max-width: 55rem) {
    .blogpost-headline {
        width: 100%;
    }
    .blogpost-imgs {
        width: 39rem;
    }
    .blogpost-text {
        width: 39rem;
    }
}

@media screen and (max-width: 40rem) {
    .blogpost-headline {
        width: 100%;
        font-size: 1.5em;
    }
    .blogpost-imgs {
        width: 100%;
    }
    .blogpost-text {
        width: 95%;
    }
}