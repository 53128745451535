.footer-root {
    padding: 0.625em;
    background-color: #004389;
    color: grey;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.footer-root a {
    color: grey;
}
