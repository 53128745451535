.header-root {
    height: 10em;
    width: 100%;
    background-color: #004389;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1em;
}

.header-logo {
    width: 18.75em;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.header-slogan > h1 {
    margin: 0;
    color: white;
    font-weight: 400;
    text-align: center;
}


.header-slogan > h3 {
    margin: 0;
    color: white;
    font-weight: 400;
    text-align: center;
    color: #f58220;
}

@media screen and (max-width: 55em) {
    .header-root {
        height: 8em;
    }
    .header-slogan > h1 {
        font-size: 1.25em;
    }
    .header-slogan > h1 {
        font-size: 1.125em;
    }
    .header-logo {
        width: 15.625em;
    }
}

@media screen and (max-width: 40em) {
    .header-slogan > h1 {
        font-size: 0.9375em;
    }
    .header-slogan > h1 {
        font-size: 0.8em;
    }
}