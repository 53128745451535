.post-root {
}

.post {
    width: 50em;
    height: 45em;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 55em) {
    .post {
        width: 40em;
        height: 100%;
    }
}

@media screen and (max-width: 40em) {
    .post {
        width: 20em;
    }
}

.post-edit {
    color: white;
}

.post-image {
    height: 20em;
    overflow: hidden;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 40em) {
    .post-image {
        height: 10em;
    }
}

.post-image > img {
    width: 100%;
    height: auto;
}

.post-image-edit {
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@media screen and (max-width: 55em) {
    .post-image-edit {
        height: 10em;
    }
}

.edit-explanation {
    font-size: small;
    color: white;
}

.post-image-edit:hover {
    cursor: pointer
}

.post-image-skeleton {
    width: 100%;
    height: 20em;
}

.post-content {
    background-color: #004389;
    color: white;
    padding: 1em;
    flex-grow: 1;
    display: grid;
    grid-template:
        "slogan slogan"   5em
        "text   info"   auto /
        60%     1fr;
    gap: 1.5em;
}

@media screen and (max-width: 40em) {
    .post-content {
        background-color: #004389;
        color: white;
        padding: 1em;
        flex-grow: 1;
        display: grid;
        grid-template:
            "slogan"   4em
            "text"   auto
            "info"   auto /
            1fr;
        gap: 1.5em;
    }
}

.post-content-edit {
    background-color: #004389;
    color: white;
    padding: 1em;
    flex-grow: 1;
    display: grid;
    grid-template:
        "slogan slogan"   7.5em
        "text   info"   auto /
        60%     1fr;
    gap: 1.5em;
}

@media screen and (max-width: 55em) {
    .post-content-edit {
        background-color: #004389;
        color: white;
        padding: 1em;
        flex-grow: 1;
        display: grid;
        grid-template:
            "slogan"   10em
            "info"   auto
            "text"   auto /
            1fr;
        gap: 1.5em;
    }
}

.post-slogan {
    grid-area: slogan;
    color: #f58220;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.edit-post-slogan {
    gap: 0.3125em;
}

.edit-post-slogan > h4 {
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}

.post-slogan > h1 {
    font-weight: 900;
    font-family: 'Helvetica';
    font-size: 2.2em;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center; 
}

@media screen and (max-width: 40em) {
    .post-slogan > h1 {
        font-size: 1.25em;
    }
}

.editpost-upload-image-input {
    display: none;
}

.post-info {
    grid-area: info;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
}

.post-info > h1 {
    font-weight: 900;
    font-family: 'Helvetica';
    font-size: 1.25em;
    margin-top: 0px;
    margin-bottom: 0px;
}

@media screen and (max-width: 40em) {
    .post-info > h1 {
        font-size: 1em;
    }
}

.post-info-type {
    display: flex;
    align-items: center;
    gap: 0.625em;
}

.post-info-icon {
    margin-left: -0.3125em;
    display: flex;
    align-items: center;
    gap: 0.3125em;
}

.post-info-edit {
    padding: 0.5em;
    background-color: white;
    border-radius: 0.1875em;
}

.edit-post-textfield {
    color: white;
}

.post-info-name {
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.post-text {
    grid-area: text;
    color: white;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.post-text > h1 {
    font-weight: 900;
    font-family: 'Helvetica';
    font-size: 1.25em;
    margin-top: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    overflow-wrap: break-word;
}

@media screen and (max-width: 40em) {
    .post-text > h1 {
        max-width: 100%;
        width: 20em;
        font-weight: 600;
        font-size: 0.75em;
        overflow: hidden;
        overflow-wrap: break-word;
    }
}

.edit-post-text {
    background-color: white;
    border-radius: 0.1875em;
}

.post-bottom-area {
    background-color: #004389;
}

.post-share-iconbar {
    display: flex;
    padding-left: 0.5em;
    padding-right: 0.5em;
    justify-content: flex-end;
    align-items: center;
    height: 3em;
}

.dehoga-icon-bottom {
    height: 100%;
    width: 7em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

.editpost-send-post {
    display: flex;
    height: 3em;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 0.625em;
}

.textfield {
    width: 1.875em;
}

.company-name-textfield {
    width: 9.375em;
}

.company-zip-textfield {
    width: 6.25em;
}

.company-loc-textfield {
    width: 7.5em;
}

.company-type-select {
    margin-top: 1em;
    width: 9.375em;
}

.company-num-textfield {
    width: 3.125em;
}

.click-icon {
    color: #f58220;
}

.click-icon:hover {
    cursor: pointer;
}