.headspoon-root {
    height: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headspoon {
    width: 20em;
    height: 4em;
    text-align: center;
    padding-top: 4em;
    z-index: 100;
    transition: transform 500ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
}

.headspoon .spoon {
    width: 15em;
    transform: translate(0, -50%) rotate(45deg);
}

@media screen and (max-width: 55em) {
    .headspoon-root {
        height: 5em;
    }
    .headspoon .spoon {
        width: 9em;
    }
}

@media screen and (max-width: 40em) {
    .headspoon-root {
        height: 4em;
    }
    .headspoon .spoon {
        width: 6em;
    }
}