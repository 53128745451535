.postcarousel-root {
    background-color: #C8C8C8;
}

.postcarousel {
    height: 47em;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 55em) {
    .postcarousel-root {
        padding-top: 1em;
        padding-bottom: 1em;
        height: 100%;
    }

    .postcarousel {
        height: 45em;
    }
}

@media screen and (max-width: 40em) {
    .postcarousel {
        height: 100%;
    }
}