.spoons-root {
    padding: 1em;
    background-color: #004389;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2em;
}
.spoons h2 {
    color: #fff;
}

.spoons-button-area {
    height: 2em;
    padding-bottom: 15em;
}

.spoons {
    max-width: 50em;
    width: 100%;
    text-align: center;
    padding-bottom: 2.5em;
    position: relative;
}

.spoons .bowl {
    background: #004389;
    height: auto;
    width: 30em;
    z-index: 100;
    position: relative;
}
.spoons .spoon {
    position: absolute;
    height: 15em;
    z-index: 89;
    left: 50%;
    bottom: 27%;
}

@media screen and (max-width: 55em) {
    .spoons .spoon {
        width: 8em;
        bottom: 15%;
    }
    .spoons .bowl {
        width: 20em;
    }
    .spoons-button-area {
        padding-bottom: 7em;
    }
}

@media screen and (max-width: 40em) {
    .spoons .spoon {
        width: 6em;
        bottom: 8%;
    }
    .spoons .bowl {
        width: 15em;
    }
    .spoons-button-area {
        padding-bottom: 5em;
    }
}